<template>
<div id="category_hba_2yo">
    <h2 class="horse_lineup_category line-left" :style="{'border-left-color': getCategory(cat.category, 5)}">
        {{getCategory(cat.category, 2) + '　'}}
        <span class="d-inline-block">{{cat.end_datetime | timeFormat}} {{$t('LINEUP_ITEMS_AUCTION_END_LABEL') + (cat.extend_flag_count && cat.extend_flag_count > 0 ? $t('LINEUP_ITEMS_AUCTION_AUTO_EXTENDED_LABEL') : '')}}</span>
    </h2>
    <div class="horse_lineup_table">
        <DataTable
            :logined="logined"
            :category="cat.category"
            :exhibition_no="exhibition_no"
            :items="items"
            :allCats="allCats"
            :shortenSellerConstants="grandParent.shortenSellerConstants"
            :favoriteItem="favoriteItem"
            :getGender="getGender"
        />
    </div>
</div>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import DataTable from './DataTableOriginal'
export default {
  name       : 'CategoryInfo',
  components : {
    DataTable
  },
  props : {
    exhibition_no : {
      type    : Number,
      default : Object
    },
    logined : {
      type    : Boolean,
      default : false
    },
    grandParent : {
      type    : Object,
      default : Object
    },
    cat : {
      type    : Object,
      default : Object
    },
    allCats : {
      type    : Array,
      default() {
        return []
      }
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    },
    favoriteItem : {
      type    : Function,
      default : Object
    }
  },
  mixins : [CommonFilters],
  data() {
    return {
      items : [],
    }
  },
  methods : {
  },
  created() {
    this.items = this.cat && this.cat.items ? this.cat.items : []
  },
  watch : {
    // Update favorite status
    cat : {
      handler(val, oldVal) {
        console.log('cat.items changed')
        this.items = val && val.items ? val.items : []
      },
      deep : true
    }
  }
}
</script>

<style scoped>
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  h2.horse_lineup_category.line-left {
    border-left-width: 15px;
    border-left-style: solid;
    border-left-color: #D60000;
  }
</style>
<style scoped src="@/assets/css/top.css"></style>
