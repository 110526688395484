<template>
<main class="top_main">
  <div class="top_main_view">
    <div class="top_main_schedule">
    </div>
    <div class="top_main_title">
      <h2 class="">SATURDAY AUCTION</h2>
    </div>
    <p class="top_sub">
      毎週<span>土曜日</span>開催
    </p>
  </div>
  <div class="top_main_guidance d-none">
    <p class="guidance">
      新規利用申請・ニックネーム変更は、前日水曜12:00までにお願い致します。詳しくはコチラをご覧下さい。
    </p>
  </div>

  <section id="schedule" class="u-mt50 u-mb50">
    <Schedules/>
  </section>

  <section id="news_01" class="u-mb50">
    <Notices :parent="$data" :priority="1"/>
  </section>

  <section id="auction">
    <Auctions
      :parent="$data"
      :getCategory="getCategory"
      :getColor="getColor"
      :getGender="getGender"
      :updateFavoriteChangedItems="updateFavoriteChangedItems"
    />
  </section>

  <LineupItems
    :parent="$data"
    :getCategory="getCategory"
    :getColor="getColor"
    :getGender="getGender"
    :updateFavoriteChangedItems="updateFavoriteChangedItems"
    :logined="$cookies.get($define.LOCAL_STORE_LABEL.SESSION_TOKEN)!=null"
  />

  <section id="news_02" class="u-mb50">
    <Notices :parent="$data" :priority="2"/>
  </section>

  <section id="guide_list" class="u-mb50">
    <GuideList />
  </section>
</main>
</template>

<script>
import Auctions from '../components/Top/Auctions'
import GuideList from '../components/Top/GuideList'
import LineupItems from '../components/Top/LineupItems'
import Notes from '../components/Top/Notes'
import Notices from '../components/Top/Notices'
import Schedules from '../components/Top/Schedules'
import LoadConstant from '../components/Top/loadConstant.js'

export default {
  components : {
    LineupItems,
    Auctions,
    Notices,
    Schedules,
    GuideList,
    Notes
  },
  mixins : [LoadConstant],
  data() {
    return {
      searchPageRouterName : this.$define.PAGE_NAMES.TOP_PAGE_NAME,
      favoriteChangedItems : null
    }
  },
  methods : {
    updateFavoriteChangedItems(fItems) {
      this.favoriteChangedItems = fItems
    }
  }
}
</script>

<style scoped src="@/assets/css/top.css"></style>
