<template>
<table id="horse_table1">
    <thead>
      <tr>
        <th v-for="(header, index) in headers"
          v-show="header.filterable"
          :key="header.value"
          :class="header.sortable ? 'th_common ' + header.class + ' cursor_pointer' : 'th_common ' + header.class"
          :data-id="header.data_id"
          @click="changeSort(index)"
        >
        <span class="is-pc header_text">{{header.text}}</span>
        <span class="is-sp header_text">{{header.sp_text || header.text}}</span>
        <span class="sort_icon" v-if="header.sortable"><Icon :icon="iconName(index)"/></span>
        </th>
      </tr>
    </thead>
    <tbody>
        <tr v-for="(item, index1) in computedItems" :key="category + '' + index1">
            <td class="td_name" :style="headers[0].style || ''">
            <a :class="item.basic_info_url ? '' : 'no-link'" @click="gotoLinkBase(item.basic_info_url)">{{item.nickname}}</a>
            </td>
            <td class="td_fav" :style="headers[1].style || ''">
            <button
                :value="$t('ITEM_SEARCH_FAVORITE_LABEL')"
                class="btn_fav"
                :data-fav="item.isFavorite ? 'on' : 'off'"
                :disabled="!logined"
                @click.stop="favoriteItem(item.isFavorite, item.exhibition_item_no)"
                id=""/>
            </td>
            <td
              class="td_sex"
              :style="headers[2].style || ''"
              :data-sex="String(item.gender) === '1' ? 'male' : String(item.gender) === '2' ? 'female' : 'gelding'">
            </td>
            <td class="td_year" :style="headers[3].style || ''">
            {{String(item.age) === '0' ? $t('LINEUP_ITEMS_AGE_0_LABEL') : item.age}}<span class="is-pc" v-if="typeof item.age !== 'undefined' && item.age !== null && item.age !== ''">{{$t('TOP_PAGE_AGE_LABEL')}}</span>
            </td>
            <td class="td_seller" :style="headers[4].style || ''"><span class="is-pc">{{item.register_seller}}</span><span class="is-sp">{{item.shorten_register_seller}}</span></td>
            <td
              v-if="Number(category) > 3 && Number(category) !== 16"
              class="td_prize"
              :style="headers[5].style || ''"
              v-bind:class="{'u-ta-c': item.total_achievements === '0-0-0-0'}"
            >
            <a v-if="item.total_achievements === '0-0-0-0'" class="no-link">
                {{$t('LINEUP_ITEMS_MISHUSSO_LABEL')}}
            </a>
            <a
                v-else-if="item.total_prize_money"
                :class="item.basic_info_url ? '' : 'no-link'"
                @click="gotoLinkRecord(item.basic_info_url)">
                {{item.total_prize_money | prizeMoney2String}}<span class="d-inline-block" v-if="item.total_prize_money">{{$t('ITEM_ROW_PRICE_CURRENCY_MANYEN_MARK')}}</span>
            </a>
            </td>
            <td class="td_moving" :style="headers[6].style || ''" v-if="Number(category) > 3 && Number(category) !== 16">{{String(item.southkanto_moveable) === '1' ? $t('ITEM_SEARCH_KA_LABEL') : $t('ITEM_SEARCH_HI_LABEL')}}</td>
            <td class="td_weight" :style="headers[7].style || ''" v-if="Number(category) !== 16">
              {{item.weight}}<span class="is-pc" v-if="item.weight">{{$t('ITEM_ROW_WEIGHT_KG_MARK')}}</span>
            </td>
            <td class="td_father" :style="headers[8].style || ''" v-if="Number(category) === 16">{{item.stallion_name}}</td>
            <td class="td_link">
                <a @click="btnDetailClick(exhibition_no, item)">
                    {{$t('ITEM_ROW_DETAIL_LINK_LABEL')}}
                </a>
            </td>
        </tr>
    </tbody>
</table>
</template>

<script>
import CommonFilters from '@/common/commonFilters'
import {Icon} from '@iconify/vue2'
export default {
  name       : 'DataTableOriginal',
  components : {
    Icon
  },
  props : {
    logined : {
      type    : Boolean,
      default : false
    },
    category : {
      type    : String,
      default : null
    },
    exhibition_no : {
      type    : Number,
      default : Object
    },
    items : {
      type    : Array,
      default() {
        return []
      }
    },
    allCats : {
      type    : Array,
      default() {
        return []
      }
    },
    favoriteItem : {
      type    : Function,
      default : Object
    },
    shortenSellerConstants : {
      type    : Array,
      default() {
        return []
      }
    },
    getGender : {
      type    : Function,
      default : Object
    },
  },
  mixins : [CommonFilters],
  data() {
    return {
      computedItems : [],
      headers       : [],
      sorterState   : {}, // {asc: false, column: 'nickname'}
    }
  },
  created() {
    // Set headers
    this.headers = [
      {
        text        : this.$t('LINEUP_ITEMS_NAME_LABEL'),
        align       : 'center',
        filterable  : true,
        sortable    : true,
        sort_column : 'nickname',
        value       : 'nickname',
        class       : 'th_name',
        data_id     : 'name',
        style       : 'min-width: 53px;'
      },
      {
        text       : '',
        align      : 'center',
        filterable : true,
        sortable   : false,
        value      : 'favorite',
        class      : 'btn_fav',
        data_id    : 'year'
      },
      {
        text        : this.$t('LINEUP_ITEMS_GENDER_LABEL'),
        sp_text     : this.$t('LINEUP_ITEMS_GENDER_LABEL_SP'),
        align       : 'start',
        filterable  : true,
        sortable    : true,
        sort_column : 'gender_sort',
        value       : 'gender',
        class       : 'th_sex break_white_space',
        data_id     : 'sex',
        // Style: 'min-width: 35px;'
      },
      {
        text        : this.$t('LINEUP_ITEMS_AGE_LABEL'),
        sp_text     : this.$t('LINEUP_ITEMS_AGE_LABEL_SP'),
        align       : 'center',
        filterable  : true,
        sortable    : true,
        sort_column : 'age_sort',
        value       : 'age',
        class       : 'th_year break_white_space',
        data_id     : 'year',
        // Style: 'min-width: 35px;'
      },
      {
        text       : this.$t('LINEUP_ITEMS_REGISTER_SELLER_LABEL'),
        align      : 'center',
        filterable : true,
        sortable   : false,
        value      : 'register_seller',
        class      : 'th_seller',
        data_id    : 'seller'
      },
      {
        text        : this.$t('LINEUP_ITEMS_TOTAL_PRIZE_MONEY_LABEL'),
        align       : 'center',
        filterable  : (Number(this.category) > 3 && Number(this.category) !== 16),
        sortable    : true,
        sort_column : 'total_prize_money_sort',
        value       : 'total_prize_money',
        class       : 'th_prize',
        data_id     : 'prize',
        style       : 'min-width: 43px;'
      },
      {
        text        : this.$t('LINEUP_ITEMS_SOUTHKANTO_MOVEABLE_LABEL'),
        align       : 'center',
        filterable  : (Number(this.category) > 3 && Number(this.category) !== 16),
        sortable    : true,
        sort_column : 'southkanto_moveable',
        value       : 'southkanto_moveable',
        class       : 'th_moving break_white_space',
        data_id     : 'moving'
      },
      {
        text        : this.$t('LINEUP_ITEMS_WEIGHT_LABEL'),
        align       : 'center',
        filterable  : (Number(this.category) !== 16),
        sortable    : true,
        sort_column : 'weight_sort',
        value       : 'weight',
        class       : 'th_weight',
        data_id     : 'weight',
        style       : 'min-width: 43px;'
      },
      {
        text        : this.$t('LINEUP_ITEMS_STALLION_LABEL'),
        align       : 'center',
        filterable  : (Number(this.category) === 16),
        sortable    : true,
        sort_column : 'stallion_name',
        value       : 'stallion_name',
        class       : 'th_father',
        data_id     : 'father'
      },
      {
        text       : this.$t('LINEUP_ITEMS_DETAIL_BTN_LABEL'),
        align      : 'center',
        filterable : true,
        sortable   : false,
        value      : 'detail',
        class      : 'th_link',
        data_id    : 'link'
      },
    ]
    // Load items
    this.reloadItems()
  },
  methods : {
    reloadItems() {
      if (this.items) {
        this.computedItems = this.items.map(item => {
          return {
            exhibition_item_no  : item.exhibition_item_no,
            item_no             : item.item_no,
            isFavorite          : item.isFavorite,
            nickname            : item.free_field.nickname,
            gender              : item.free_field.gender,
            age                 : item.free_field.age,
            register_seller     : item.free_field.register_seller,
            total_achievements  : item.free_field.total_achievements,
            total_prize_money   : item.free_field.total_prize_money,
            southkanto_moveable : item.free_field.southkanto_moveable,
            weight              : item.free_field.weight,
            stallion_name       : item.free_field.stallion_name,
            basic_info_url      : item.free_field.basic_info_url,

            shorten_register_seller  : this.shortenSeller(item.free_field.register_seller),
            total_prize_money_sort   : item.free_field.total_achievements === '0-0-0-0' ? -1 : Number(item.free_field.total_prize_money || ''), // 未出走は賞金額0より下の扱いにする
            weight_sort              : item.free_field.weight ? Number(item.free_field.weight) : null,
            gender_sort              : Number(this.getGender(item.free_field.gender, true) || ''),
            age_sort                 : Number(item.free_field.age || ''),
          }
        })
        this.computedItems = this.sortedItems()
      } else {
        this.computedItems = []
      }
    },
    btnDetailClick(exhibition_no, item) {
      console.log('btnDetailClick')

      /*
       * Limit the result
       * Exhibition Item nos from top
       */
      const exhItemNosFromTop = []
      if (this.allCats) {
        loop1:
        for (const tmpCat of this.allCats) {
          if (tmpCat.items) {
            for (const tmpItem of tmpCat.items) {
              exhItemNosFromTop.push(String(tmpItem.exhibition_item_no))
              if (String(tmpItem.exhibition_item_no) === String(item.exhibition_item_no)) {
                break loop1
              }
            }
          }
        }
      }
      console.log('exhItemNosFromTop: ', exhItemNosFromTop)

      const allCatCounts = exhItemNosFromTop.length
      const currentCatCount = this.items.length

      /*
       * // Get showed items
       * let tmpShowedItemNos = null
       * if (exhItemNosFromTop.length > this.$define.LIMIT_PER_PAGE[0]) {
       *   const mod = parseInt(exhItemNosFromTop.length / this.$define.LIMIT_PER_PAGE[0])
       *   const lastBatch = mod * this.$define.LIMIT_PER_PAGE[0]
       *   tmpShowedItemNos = exhItemNosFromTop.splice(0, lastBatch)
       * }
       * console.log('tmpShowedItemNos: ', tmpShowedItemNos)
       */

      this.$routerGoNewWindow(
        this.$define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME,
        item.exhibition_item_no,
        {
          filter : encodeURIComponent(JSON.stringify({
            // ShowedItemNos            : tmpShowedItemNos,
            category                 : allCatCounts > this.$define.LIMIT_PER_PAGE[0] && currentCatCount <= this.$define.LIMIT_PER_PAGE[0] ? [{categoryNo : this.category}] : null,
            exhibitionNos            : [exhibition_no],
            endDatetimeNotExtendSort : 'asc',
            categorySort             : 'asc',
            limit                    : 500,
            initLimit                : 500
          }))
        }
      )
    },
    gotoLinkBase(url) {
      console.log('url: ', url)
      // Demo url: ./details/detail_123456781.html
      if (url) {
        window.open(url, '_blank')
      }
    },
    gotoLinkRecord(url) {
      console.log('url: ', url)
      // Demo url: ./details/detail_123456781.html
      if (url) {
        window.open(`${url}record/`, '_blank')
      }
    },
    shortenSeller(name) {
      let ret = name || ''
      const kabu = ['(株)', '(有)', '(同)', '（株）', '（有）', '（同）']
      kabu.map(x => {
        ret = ret.replaceAll(x, '')
      })
      if (this.shortenSellerConstants) {
        this.shortenSellerConstants.map(y => {
          ret = ret.replaceAll(y.value2, y.value1)
        })
      }
      return ret
    },
    changeSort(index) {
      const column = this.headers[index].sort_column || this.headers[index].value
      console.log('changeSort: ', column)
      if (!column || !this.headers[index].sortable) {
        return
      }
      if (column && this.computedItems) {
        // If column changed or sort was descending change asc to true
        const columnRepeated = this.sorterState.column === column
        this.sorterState.column = column
        this.sorterState.asc = !(columnRepeated && this.sorterState.asc)
        this.$emit('update:sorter-value', this.sorterState)
        this.computedItems = this.sortedItems()
      }
    },
    sortedItems() {
      const col = this.sorterState.column
      if (!col) {
        return this.computedItems
      }
      // If values in column are to be sorted by numeric value they all have to be type number
      const flip = this.sorterState.asc ? 1 : -1
      const ret = this.computedItems.slice().sort((item, item2) => {
        const value  = typeof item[col] === 'undefined' || item[col] === null ? '' : item[col]
        const value2 = typeof item2[col] === 'undefined' || item2[col] === null ? '' : item2[col]
        const a = typeof value === 'number' ? value : String(value).toLowerCase()
        const b = typeof value2 === 'number' ? value2 : String(value2).toLowerCase()
        let abRes = a > b ? 1 * flip : b > a ? -1 * flip : 0

        // 体重の未入力馬の場合はいつも最下位にする
        if (col === 'weight_sort') {
          if (a === '' || a === 'undefined') {
            abRes = 1
          } else if (b === '' || b === 'undefined') {
            abRes = -1
          }
          console.log('abRes: ', abRes)
        }

        /*
         * 金額順での並べ替えにする場合に、未出走は賞金額0より下の扱いにする
         * if (col === 'total_prize_money_sort') {
         *   if (item.total_achievements === '0-0-0-0') {
         *     abRes = 1
         *   } else if (item2.total_achievements === '0-0-0-0') {
         *     abRes = -1
         *   }
         *   console.log('abRes: ', abRes)
         * }
         */

        return abRes
      })
      return ret
    },
    getIconState(index) {
      const direction = this.sorterState.asc ? 'asc' : 'desc'
      return this.headers[index].sort_column === this.sorterState.column ? direction : 0
    },
    iconName(index) {
      const state = this.getIconState(index)
      switch (state) {
      case 'asc':
        return 'mdi:arrow-upward'
      case 'desc':
        return 'mdi:arrow-downward'
      default:
        return ''
      }
    },
  },
  watch : {
    // Update favorite status
    items : {
      handler(val, oldVal) {
        console.log('items changed')
        this.reloadItems()
      },
      deep : true
    }
  }
}
</script>

<style scoped>
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  #lineup .content_wrap p,
  #lineup .lineup_subtitle p,
  .search_acodion .search p {
    margin-bottom: initial !important;
  }
  .td_link a, .td_name a, .td_prize a {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .td_name .no-link, .td_prize .no-link {
    cursor: text !important;
    color: inherit;
  }
  h2.horse_lineup_category.line-left {
    border-left-width: 15px;
    border-left-style: solid;
    border-left-color: #D60000;
  }
  .no_underline {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .cursor_pointer {
    cursor: pointer;
  }
  .break_white_space {
    white-space: break-spaces !important;
  }

  .th_common {
    position: relative;
    height: 30px;
  }
  .header_text {
    position: relative;
  }
  .sort_icon {
    position: absolute;
    margin: 0;
    top: 50%;
  }
  @media screen and (min-width: 769px) {
    .sort_icon {
      right: 1%;
      transform: translate(-1%, -45%);
    }
  }
  @media screen and (max-width: 768px) {
    .sort_icon {
      right: 0;
      transform: translate(0%, -40%);
    }
  }
</style>
<style scoped src="@/assets/css/top.css"></style>
