<template>
  <item-search
    :parent="$data"
    :grandParent="parent"
    :search="searchAuctionItems"
    :printItemsCsv="printItemsCsv"
    :favorite="favorite"
    :getCategory="getCategory"
    :getColor="getColor"
    :getGender="getGender"
    :updateFavoriteChangedItems="updateFavoriteChangedItems"
  />
  </template>
<script>
import Methods from '@/api/methods'
import ItemSearch from '../../components/ItemList/ItemSearch'

export default {
  components : {
    ItemSearch
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    logined : {
      type    : Boolean,
      default : false
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    },
    updateFavoriteChangedItems : {
      type    : Function,
      default : Object
    }
  },
  data() {
    return {
      nameTag        : 'AUCTION_PAGE_SEARCH_HEADER',
      typeTag        : 'AUCTION_PAGE_SEARCH_TYPE',
      detailPageName : this.$define.PAGE_NAMES.AUCTION_DETAIL_PAGE_NAME
    }
  },
  methods : {
    searchAuctionItems(params) {
      return Methods.apiExcute('public/search-auction-items', this.$i18n.locale, Object.assign({exhClass : 1}, params))
    },
    printItemsCsv(params) {
      return Methods.apiExcute('private/print-auction-items-csv', this.$i18n.locale, Object.assign({exhClass : 1}, params))
    },
    favorite(params) {
      return Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
    }
  }
}
</script>

<style scoped src="@/assets/css/top.css"></style>
