<template>
<div class="content_wrap u-ta-c">
  <a @click="$routerGo($define.PAGE_NAMES.NEW_MEMBER_REGISTRATION_PAGE_NAME)" class="auction_guide_link u-mb20">サタデーオークション会員登録</a>
</div>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
    }
  },
  methods : {

  }
}
</script>

<style scoped>
  #guide_list .content_wrap p {
    margin-bottom: initial !important;
  }
  .auction_guide_link {
    cursor: pointer !important;
    text-decoration: none !important;
  }

  @media screen and (max-width:768px) {
    .auction_guide_link::after{
      height: 2rem !important;
    }
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
