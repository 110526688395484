<template>
<div class="content_wrap u-ta-c">
  <h2>注意事項</h2>
  <p class="u-fc-yellow">必ずお読み下さい</p>

  <div class="notes_list">
    <ul class="">
      <li>【サラブレッドオークションの利用登録申請について】</li>
      <li>楽天会員にてご使用になられているものと同じニックネームで、サラブレッドオークションの利用登録申請をお願いいたします。</li>
      <li>利用登録申請後にニックネームを変更された場合は、オークション事務局まで必ずご連絡ください。</li>
    </ul>

    <ul class="">
      <li>【サラブレッドオークションへの入札について】</li>
      <li>誤った金額を入力された場合でも、一度入札された金額は取消や変更ができかねますので、入札金額の誤りにはくれぐれもご注意ください。</li>
      <li>自己入札（吊り上げ）および遅延入札（終了時間間際の入札）など、スムーズなオークションの進行および運営を妨げ、損害または不利益を生じると判断される悪質な迷惑行為ととれる入札については、取り消しをさせていただきます。また参加者資格の剥奪等の措置を講じることがあることを予めご了承ください。</li>
    </ul>

    <ul class="">
      <li>【入札単位と自動入札について】</li>
      <li>サラブレッドオークションの入札は、出品馬により入札単位が異なります。入札単位は各出品ページをご確認ください。</li>
      <li>予算金額を入力いただけると、自動入札機能による入札が可能です。</li>
    </ul>

    <ul class="">
      <li>【落札馬の引き渡し】</li>
      <li>落札馬の引き渡しにかかる必要経費は、落札者様のご負担となります。</li>
      <li>引き取り前に獣医検査を行うことが可能です。出品馬の現況は、出品者による申告であり、引渡し時点での状況を保証しているものではありません。なお、この際の獣医検査にかかる費用は、落札者様のご負担となります。</li>
      <li>落札馬の引き渡し後、【馬用個体識別マイクロチップ（ＭＣ）】の読み取り不良により、再埋め込みの必要が生じた際の埋め込みに関する全ての経費は、落札者様のご負担となります。</li>
    </ul>

    <ul class="">
      <li>【その他】</li>
      <li>落札者は、出品馬の公開情報を全て了承したものとし、その情報が原因となる契約の解除は一切認められません。</li>
      <li>実馬を見学いただくことが可能です。事前に申し込みが必要ですので、ご希望の方はオークション事務局までお問い合わせください。</li>
    </ul>
  </div>

  <div class="seller_guide">
    <h3>出品をご希望の方はこちら（落札を希望される方もご覧ください）</h3>
    <div class="d-flex flex-wrap">
      <div class="seller_guide_link">
        <ul>
          【現役競走馬出品または落札をご希望の方】
          <li>サラブレッドオークション現役競走馬業務規程（PDF）</li>
          <li>現役競走馬上場募集要項（PDF）</li>
          <li>現役競走馬販売申込書（PDF）</li>
          <li>Excelファイルはこちらから</li>
        </ul>
      </div>
      <div class="seller_guide_link">
        <ul>
          【現役競走馬出品または落札をご希望の方】
          <li>サラブレッドオークション現役競走馬業務規程（PDF）</li>
          <li>現役競走馬上場募集要項（PDF）</li>
          <li>現役競走馬販売申込書（PDF）</li>
          <li>Excelファイルはこちらから</li>
        </ul>
      </div>
    </div>
  </div>
</div>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
    }
  },
  methods : {

  }
}
</script>

<style scoped>
  .v-application ul, .v-application ol {
    padding-left: initial !important;
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
