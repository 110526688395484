<template>
<div v-if="newNotice.body || newNotice.title" :class="'news_0' + priority +'_contents'">
  <h2 v-if="priority === 1" class="news_01_title">{{newNotice.title1 || ' '}}</h2>
  <div v-if="priority === 1" class="row">
    <p class="news_01_subtitle">{{newNotice.title || ''}}</p>
    <p class="news_01_date">{{newNotice && newNotice.sub_title ? newNotice.sub_title : ''}}</p>
  </div>
  <ul v-if="priority === 1" class="row border-t-d u-pt20 u-pb20">
    <li class="col-12" v-bind:class="{'col-md-8': newNotice.file && newNotice.file.length > 0, 'col-md-12': !(newNotice.file && newNotice.file.length > 0)}">
      <p class="news_01_text" v-html="newNotice ? newNotice.body : ''"></p>
    </li>
    <li class="col-12 col-md-4" v-if="newNotice.file && newNotice.file.length > 0">
      <div class="news_01_imgbox">
        <img v-if="newNotice.file" :src="newNotice.file[0] | getFullImagePath" class="" @click="openGallery(0)"/>
      </div>
    </li>
  </ul>

  <div v-if="priority === 2" class="d-flex">
    <h2 class="news_02_title">{{newNotice.title || ''}}</h2>
  </div>
  <ul v-if="priority === 2" class="row border-t-d u-pt20 u-pb20">
    <li class="col-12" v-bind:class="{'col-md-8': newNotice.file && newNotice.file.length > 0, 'col-md-12': !(newNotice.file && newNotice.file.length > 0)}">
      <p class="news_01_text" v-html="newNotice ? newNotice.body : ''"></p>
    </li>
    <li class="col-12 col-md-4" v-if="newNotice.file && newNotice.file.length > 0">
      <div class="news_01_imgbox">
        <img v-if="newNotice.file" :src="newNotice.file[0] | getFullImagePath" class="" @click="openGallery(0)"/>
      </div>
    </li>
  </ul>
</div>
</template>
<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import {Icon} from '@iconify/vue2'
export default {
  components : {
    Icon
  },
  props : {
    parent : {
      type    : Object,
      default : Object
    },
    priority : {
      type    : Number,
      default : 1
    }
  },
  mixins : [CommonFilters],
  data() {
    return {
      newNotice    : {},
      showLightbox : false,
    }
  },
  created() {
    this.getNewNotice()
  },
  methods : {
    getNewNotice() {
      this.$store.state.loading = true
      Methods.apiExcute('public/get-new-notices', this.$i18n.locale, {
        display_code : this.priority === 1 ? 2 : 1
      }).then(data => {
        this.$store.state.loading = false
        this.newNotice = data && data.length > 0 ? data[0] : {}
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    openGallery(index) {
      console.log('openGallery: ', index)
      this.$routerGoNewWindow(this.$define.PAGE_NAMES.PRINTER, null, {file_path : this.newNotice.file[0]})
    },
  },
  watch : {
    '$i18n.locale'() {
      this.getNewNotice()
    }
  }
}
</script>
<style scoped>
  .v-application ul, .v-application ol {
    padding-left: initial !important;
  }
  .v-application ul li >>> p {
    margin-bottom: initial !important;
  }
  .v-application p {
    margin-bottom: initial !important;
  }

  #news_02 .news_02_contents .row,
  #news_01 .news_01_contents .row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .news_01_imgbox img {
    cursor: pointer;
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
