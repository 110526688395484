<template>
<div class="auction_schedule">
    <p class="u-pb10 u-ta-c u-fw700 schedule_title">サタデーオークション　開催スケジュール(毎週)</p>
    <table class="schedule_txt u-mt10">
      <tr>
        <td>
          水曜日:
        </td>
        <td>
          夜
        </td>
        <td>
          その週の上場馬を発表
        </td>
      </tr>
      <tr>
        <td>
          金曜日:
        </td>
        <td>
          夜
        </td>
        <td>
          上場馬の詳細情報を公開
        </td>
      </tr>
      <tr>
        <td>
          土曜日:
        </td>
        <td>
          12:00
        </td>
        <td>
          オークション開始
        </td>
      </tr>
    </table>
    <p style="margin-left:2%;">
      <span class="u-fs12">※ 馬の情報は随時変更・追加される場合があります。入札をされる際は常に最新の情報をご確認ください。</span>
    </p>
</div>
</template>
<script>
export default {
  props : {
    logined : {
      type    : Boolean,
      default : false
    }
  },
  data() {
    return {
    }
  },
  methods : {

  }
}
</script>
<style scoped>
  #schedule .schedule_txt, .schedule_txt p, .auction_schedule p {
    margin-bottom: initial !important;
  }
  .schedule_title {
    width: 96%;
    margin: 0 auto;
    border-bottom: 1px dashed #ccc;
  }
  .schedule_txt {
    border-top: none !important;
  }
</style>

<style scoped src="@/assets/css/top.css"></style>
