import Methods from '@/api/methods'
export default {
  data() {
    return {
      isConstantLoaded       : false,
      categoryConstants      : [],
      colorConstants         : [],
      genderConstants        : [],
      taxRateConstants       : [],
      autoPitchConstants     : [],
      kahiConstants          : [],
      umuConstants           : [],
      shortenSellerConstants : []
    }
  },
  created() {
    this.getConstants()
  },
  methods : {
    getConstants() {
      this.$store.state.loading = true
      this.isConstantLoaded = false
      Methods.apiExcute('public/get-item-search-constants', this.$i18n.locale)
        .then(data => {
          // This.$store.state.loading = false
          this.categoryConstants = data.filter(constant => constant.key_string === 'EQMO_HORSE_CATEGORY')
          this.colorConstants = data.filter(constant => constant.key_string === 'EQMO_HORSE_COLOR')
          this.genderConstants = data.filter(constant => constant.key_string === 'EQMO_HORSE_GENDER')
          this.taxRateConstants = data.filter(constant => constant.key_string === 'TAX_RATE')
          this.autoPitchConstants = data.filter(constant => constant.key_string === 'PITCH_FOLLOW_BID_PRICE')
          this.shortenSellerConstants = data
            .filter(constant => constant.key_string === 'EQMO_SHORTEN_SELLER')
            .sort((a, b) => a.sort_order - b.sort_order)

          // This.kahiConstants = data.filter(constant => constant.key_string === 'OPTION_KAHI')
          this.kahiConstants = [
            {
              value1     : '1',
              value2     : this.$t('ITEM_SEARCH_KA_LABEL'),
              sort_order : 1
            },
            {
              value1     : '0',
              value2     : this.$t('ITEM_SEARCH_FUKA_LABEL'),
              sort_order : 2
            },
          ]
          this.umuConstants = [
            {
              value1     : '0',
              value2     : this.$t('ITEM_SEARCH_UMU_MU_LABEL'),
              sort_order : 1
            },
            {
              value1     : '1',
              value2     : this.$t('ITEM_SEARCH_UMU_U_LABEL'),
              sort_order : 2
            },
          ]
          // Constant loaded successful
          this.isConstantLoaded = true
        })
        .catch(error => {
          this.$store.state.loading = false
          this.errors = Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    getCategory(id, valueOrder = 2, valueIndex = 0) {
      const tmp = this.categoryConstants.filter(x => String(x.value1) === String(id))
      const tmpSplit = tmp.length > 0 && tmp[0][`value${valueOrder}`] ? tmp[0][`value${valueOrder}`].split(',') : []
      return tmpSplit.length > 0 ? tmpSplit[valueIndex] : ''
    },
    getColor(id, valueId = 2) {
      const tmp = this.colorConstants.filter(x => String(x.value1) === String(id))
      return tmp.length > 0 ? tmp[0][`value${valueId}`] : ''
    },
    getGender(id, isGetSortOrder = false) {
      const tmp = this.genderConstants.filter(x => String(x.value1) === String(id))
      return tmp.length > 0 ? (isGetSortOrder ? tmp[0].sort_order : tmp[0].value2) : ''
    },
  }
}
