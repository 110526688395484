<template>
<section id="lineup">
  <div class="content_wrap u-pv40" v-for="(exh, index0) in exhibitions" :key="index0">
      <div class="lineup_subtitle">
          <p class="u-fs20 u-fw700">
              {{exh.data.exhibition_name}}
          </p>
          <p class="cred">
              {{exh.data.start_end_datetime}}
          </p>
      </div>
      <p class="u-fw700 u-pv20">
        {{$t('LINEUP_ITEMS_GUILD_TEXT')}}<br />
        {{$t('LINEUP_ITEMS_GUILD_TEXT1')}}<br />
        {{$t('LINEUP_ITEMS_GUILD_TEXT2')}}<a class="no_underline" @click="downloadFile(businessProcessPdf)">{{$t('LINEUP_ITEMS_GUILD_TEXT3')}}</a>{{$t('LINEUP_ITEMS_GUILD_TEXT4')}}
      </p>

      <div class="horse_lineup" v-if="exh.data.categories">
        <template v-for="(cat, index) in exh.data.categories">
          <CategoryInfo
            :key="index"
            :exhibition_no="exh.data.exhibition_no"
            :cat="cat"
            :allCats="exh.data.categories"
            :logined="logined"
            :grandParent="parent"
            :getCategory="getCategory"
            :getColor="getColor"
            :getGender="getGender"
            :favoriteItem="favoriteItem"
          />
        </template>
      </div>
  </div>
</section>
</template>
<script>
import Methods from '@/api/methods'
import CommonFilters from '@/common/commonFilters'
import DownloadFile from '@/common/downloadFile'
import CategoryInfo from '../LineupItem/CategoryInfo'
export default {
  name       : 'LineupItems',
  components : {
    CategoryInfo
  },
  props : {
    logined : {
      type    : Boolean,
      default : false
    },
    parent : {
      type    : Object,
      default : Object
    },
    getCategory : {
      type    : Function,
      default : Object
    },
    getColor : {
      type    : Function,
      default : Object
    },
    getGender : {
      type    : Function,
      default : Object
    },
    updateFavoriteChangedItems : {
      type    : Function,
      default : Object
    }
  },
  mixins : [CommonFilters, DownloadFile],
  data() {
    return {
      exhibitions : []
    }
  },
  methods : {
    getLineUpItems() {
      this.$store.state.loading = true
      Methods.apiExcute('public/search-lineup-items', this.$i18n.locale, {
        exhClass : '1' // オークション
      }).then(data => {
        this.$store.state.loading = false
        this.exhibitions = data || []

        this.exhibitions.map(exh => {
          const items = exh.data.categories || []
          /*
           * This.exhibition_name = exh.data.exhibition_name
           * let start_end_datetime =  '2022年5月26日（木） 8:00〜21:00 / 21:30 / 22:00  開催'
           */
          let start_date = ''
          let start_datetime = ''
          let end_datetime = ''
          /*
           * Let end_date = ''
           * let end_time = []
           * Start datetime
           */
          if (items && items.length > 0) {
            start_date = this.$options.filters.dateFormat(items[0].start_datetime)
            start_datetime = this.$options.filters.dateTimeDayFormat(items[0].start_datetime, 'YYYY年M月D日（ddd）H:mm')
          }
          // End datetime
          let tmp_enddts = items.map(x => x.end_datetime).filter(y => y)
          if (tmp_enddts && tmp_enddts.length > 0) {
            tmp_enddts = tmp_enddts.sort((a, b) => {
              return a > b ? 1 : -1
            })

            // Group by date, remove duplicated time
            const group_dts = tmp_enddts.reduce((r, a) => {
              const tmp_dt = this.$options.filters.dateFormat(a)
              r[tmp_dt] = r[tmp_dt] || []
              if (r[tmp_dt].indexOf(a) === -1) {
                r[tmp_dt].push(a)
              }
              return r
            }, Object.create(null))
            console.log('group_dts: ', group_dts)

            /*
             * Generating end datetime string
             * 例：2022年11月17日 16:51 / 21:20 / 9:43 / 14:59
             */
            end_datetime = Object.keys(group_dts).map(dt => {
              const dt_dat = group_dts[dt]
              const end_date = String(dt)
              const end_time = dt_dat.map(x => this.$options.filters.timeFormat(x, 'H:mm'))
              return (Object.keys(group_dts).length > 1 || end_date !== start_date ? `${end_date} ` : '') + end_time.join(' / ')
            })
          }
          // Combining: start ~ end
          exh.data.start_end_datetime = `${start_datetime}〜${end_datetime.join('、')}  ${this.$t('LINEUP_ITEMS_KAISAI_LABEL')}`
        })
      })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    favoriteItem(favorited, exhibition_item_no) {
      console.log('favoriteItem')
      if (!this.logined) {
        return
      }
      this.$store.state.loading = true
      const params = {
        favorited,
        exhibition_item_no
      }
      Methods.apiExcute('private/favorite-item', this.$i18n.locale, params)
        .then(data => {
          // This.getLineUpItems()
          const fItems = [
            {
              exhibition_item_no,
              favorited
            }
          ]
          this.updateFavoriteChangedItems(fItems)
        })
        .catch(error => {
          this.$store.state.loading = false
          Methods.parseHtmlResponseError(this.$router, error)
        })
    },
    reloadFavoriteChangedItems() {
      console.log('reloadFavoriteChangedItems')
      if (this.parent.favoriteChangedItems) {
        this.$store.state.loading = true

        // Reload all item or updated items only
        if (this.parent.favoriteChangedItems === 'reload') {
          this.getLineUpItems()
        } else {
          this.parent.favoriteChangedItems.map(fav => {
            this.exhibitions.map(exh => {
              // If (fav.exhibition_no === exh.exhibition_no) {
              exh.data.categories.map(cat => {
                cat.items.map(item => {
                  if (item.exhibition_item_no === fav.exhibition_item_no) {
                    item.isFavorite = !fav.favorited
                  }
                })
              })
              // }
            })
          })
          this.$store.state.loading = false
        }
      }
    }
  },
  watch : {
    'parent.isConstantLoaded'(value) {
      console.log('isConstantLoaded: ', value)
      if (value) {
        this.getLineUpItems()
      }
    },
    // Update favorite status
    'parent.favoriteChangedItems'(fvItems) {
      console.log('Fvorite changed items: ', fvItems)
      this.reloadFavoriteChangedItems()
    }
  }
}
</script>

<style scoped>
  .v-application ul, .v-application ol {
    padding-left: inherit !important;
  }
  #lineup .content_wrap p,
  #lineup .lineup_subtitle p {
    margin-bottom: initial !important;
  }
  .no_underline {
    cursor: pointer !important;
    text-decoration: none !important;
  }
</style>
<style scoped src="@/assets/css/top.css"></style>
